import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { ProductsService } from '../../shared/services/api/products/ProductsService';
import { VTextField, VForm, useVForm, IVFormErrors } from '../../shared/forms';
import { DetailTool } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { VDateField } from '../../shared/forms/VDateField';
import { Lens } from './components/Lens';
import { Frame } from './components/Frame';
import { LensTreatment } from '../components/LensTreatment';
import { LensType } from '../components/LensType';
import { VImageField } from '../../shared/forms/VImageField';

interface ILensTreatment {
  antireflexo: boolean;
  blue: boolean;
  fotossensivel: boolean;
  incolor: boolean;
}

interface IFormData {
  date: Date;
  image?: string;
  description: string;
  status: number;
  type: number;
  priceSell: number;
  priceBuy: number;
  amount: number;

  lensType?: number;
  lensTreatment?: ILensTreatment;
  esf?: number;
  cil?: number;
  eix?: number;
  dnp?: number;
  alt?: number;
  add?: number;

  code?: string;
  materialType?: number;
  vertical?: number;
  horizontal?: number;
  bridge?: number;
  largDiagonal?: number;
}

const formValidationSchema: yup.SchemaOf<IFormData> = yup.object().shape({
  date: yup.date().required(),
  image: yup.string().optional().max(150),
  description: yup.string().required().min(3).max(150),
  status: yup.number().required(),
  type: yup.number().required(),
  priceSell: yup.number().required(),
  priceBuy: yup.number().required(),
  amount: yup.number().required(),
  lensType: yup.number().optional(),
  lensTreatment: yup
    .object()
    .shape({
      antireflexo: yup.boolean().optional(),
      blue: yup.boolean().optional(),
      fotossensivel: yup.boolean().optional(),
      incolor: yup.boolean().optional(),
    })
    .optional(),
  esf: yup.number().optional(),
  cil: yup.number().optional(),
  eix: yup.number().optional(),
  dnp: yup.number().optional(),
  alt: yup.number().optional(),
  add: yup.number().optional(),
  code: yup.string().optional(),
  materialType: yup.number().optional(),
  vertical: yup.number().optional(),
  horizontal: yup.number().optional(),
  bridge: yup.number().optional(),
  largDiagonal: yup.number().optional(),
});

export const DetailProducts: React.FC = () => {
  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const { id = 'nova' } = useParams<'id'>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [productType, setProductType] = useState<number>(0);
  const [lensData, setLensData] = useState({
    esf: 0,
    cil: 0,
    eix: 0,
    dnp: 0,
    alt: 0,
    add: 0,
  });
  const [frameData, setFrameData] = useState({
    vertical: 0,
    horizontal: 0,
    bridge: 0,
    largDiagonal: 0,
  });

  useEffect(() => {
    if (id !== 'nova') {
      setIsLoading(true);

      ProductsService.getById(Number(id)).then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          alert(result.message);
          navigate('/products');
        } else {
          setName(result.description);
          setProductType(result.type); // Atualizar o tipo de produto ao carregar
          formRef.current?.setData(result);

          // Extrair os dados da lente ou armação e definir no estado
          if (result.type === 1) {
            const lens = {
              esf: result.esf ?? 0,
              cil: result.cil ?? 0,
              eix: result.eix ?? 0,
              dnp: result.dnp ?? 0,
              alt: result.alt ?? 0,
              add: result.add ?? 0,
            };

            setLensData(lens);
          } else if (result.type === 2) {
            const frame = {
              vertical: result.vertical ?? 0,
              horizontal: result.horizontal ?? 0,
              bridge: result.bridge ?? 0,
              largDiagonal: result.largDiagonal ?? 0,
            };
            setFrameData(frame);
          }
        }
      });
    } else {
      formRef.current?.setData({
        setName: '',
        setSerieNumber: '',
        setType: '',
        setDescription: '',
        lensTreatment: {
          antireflexo: false,
          blue: false,
          fotossensivel: false,
          incolor: false,
        },
      });
    }
  }, [id]);

  const handleSave = (dados: IFormData) => {
    // Combinar os dados da lente ou armação com os outros dados do formulário
    const combinedData: IFormData = {
      ...dados,
      ...lensData, // Adiciona os dados da lente
      ...frameData, // Adiciona os dados da armação
    };

    formValidationSchema
      .validate(combinedData, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);

        if (id === 'nova') {
          ProductsService.create(dadosValidados).then((result) => {
            setIsLoading(false);

            if (result instanceof Error) {
              alert(result.message);
            } else {
              if (isSaveAndClose()) {
                navigate('/products');
              } else {
                navigate(`/products/detail/${result}`);
              }
            }
          });
        } else {
          ProductsService.updateById(Number(id), {
            id: Number(id),
            ...dadosValidados,
          }).then((result) => {
            setIsLoading(false);

            if (result instanceof Error) {
              alert(result.message);
            } else {
              if (isSaveAndClose()) {
                navigate('/products');
              }
            }
          });
        }
      })
      .catch((errors: yup.ValidationError) => {
        console.error('Validation Errors: ', errors);
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach((error) => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleDelete = (id: number) => {
    if (confirm('Realmente deseja apagar?')) {
      ProductsService.deleteById(id).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          alert('Registro apagado com sucesso!');
          navigate('/products');
        }
      });
    }
  };

  const handleProductTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedType = Number(event.target.value); // Converte string para número
    setProductType(selectedType);
  };

  return (
    <LayoutBaseDePagina
      titulo={id === 'nova' ? 'Novo Produto' : name}
      barraDeFerramentas={
        <DetailTool
          textoBotaoNovo="Novo"
          mostrarBotaoSalvarEFechar
          mostrarBotaoNovo={id !== 'nova'}
          mostrarBotaoApagar={id !== 'nova'}
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/products')}
          aoClicarEmApagar={() => handleDelete(Number(id))}
          aoClicarEmNovo={() => navigate('/products/detail/nova')}
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave}>
        <Box
          margin={1}
          display="flex"
          flexDirection="column"
          component={Paper}
          variant="outlined"
        >
          <Grid container direction="column" padding={2} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant="indeterminate" />
              </Grid>
            )}

            <Grid item>
              <Typography variant="h6">Registro de Produto</Typography>
            </Grid>

            <Grid
              container
              direction="row"
              padding={2}
              spacing={2}
              justifyContent={'center'}
              alignContent={'center'}
            >
              <Grid item xs={12} md={4}>
                <VImageField name="image" folder="product" imageName={name} />
              </Grid>

              <Grid item xs={12} md={8}>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  justifyContent={'center'}
                  alignContent={'center'}
                >
                  <Grid
                    container
                    item
                    direction="row"
                    justifyContent={'right'}
                    spacing={2}
                  >
                    {/* Armação */}
                    {productType === 2 && (
                      <>
                        <Grid item xs={4} sm={6} md={4}>
                          <VTextField
                            fullWidth
                            select
                            name="materialType"
                            label="Material"
                            disabled={isLoading}
                          >
                            <MenuItem key="1material" value="1">
                              Acetato
                            </MenuItem>
                            <MenuItem key="2material" value="2">
                              Metal Fechado
                            </MenuItem>
                            <MenuItem key="3material" value="3">
                              Fio de Nalon
                            </MenuItem>
                            <MenuItem key="4material" value="4">
                              Balgrife
                            </MenuItem>
                          </VTextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                          <VTextField
                            fullWidth
                            name="code"
                            label="Código"
                            disabled={isLoading}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={4} sm={6} md={4}>
                      <VDateField
                        name="date"
                        label="Data"
                        disabled={isLoading}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={4} sm={6} md={4}>
                    <VTextField
                      fullWidth
                      select
                      name="status"
                      label="Status"
                      disabled={isLoading}
                    >
                      <MenuItem key="1status" value="2">
                        Ativo
                      </MenuItem>
                      <MenuItem key="2status" value="1">
                        Inativo
                      </MenuItem>
                      <MenuItem key="3status" value="3">
                        Alocado
                      </MenuItem>
                    </VTextField>
                  </Grid>

                  <Grid item xs={4} sm={6} md={8}>
                    <VTextField
                      fullWidth
                      select
                      name="type"
                      label="Tipo"
                      disabled={isLoading}
                      onChange={handleProductTypeChange} // Manipulador de mudança
                    >
                      <MenuItem key="1" value="1">
                        Lente
                      </MenuItem>
                      <MenuItem key="2" value="2">
                        Armação
                      </MenuItem>
                      <MenuItem key="3" value="3">
                        Outros
                      </MenuItem>
                    </VTextField>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <VTextField
                      fullWidth
                      type="number"
                      name="amount"
                      label="Qtd"
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <VTextField
                      fullWidth
                      name="priceSell"
                      type="number"
                      label="Preço de Venda"
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <VTextField
                      fullWidth
                      name="priceBuy"
                      type="number"
                      label="Preço de Compra"
                      disabled={isLoading}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <VTextField
                      fullWidth
                      name="description"
                      label="Descrição"
                      disabled={isLoading}
                    />
                  </Grid>
                  {/* Armação */}
                  {productType === 2 && (
                    <>
                      <Grid
                        container
                        item
                        direction="row"
                        justifyContent={'center'}
                        spacing={2}
                      >
                        <Grid item xs={12} md={9}>
                          <Frame
                            onFrameChange={setFrameData}
                            initialData={frameData}
                            isExternalLoading={isLoading}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {/* Lente */}
                  {productType === 1 && (
                    <>
                      <Grid
                        container
                        item
                        direction="row"
                        justifyContent={'center'}
                        spacing={2}
                      >
                        <Grid item md={12} lg={9} xl={4}>
                          <LensTreatment isExternalLoading={isLoading} />
                        </Grid>
                      </Grid>
                      <Grid item xs={8}>
                        <LensType isExternalLoading={isLoading} />
                      </Grid>
                      <Grid item xs={12} lg={8}>
                        <Lens
                          onLensChange={setLensData}
                          initialData={lensData}
                          isExternalLoading={isLoading}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm>
    </LayoutBaseDePagina>
  );
};
